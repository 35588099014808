import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PortfolioProjectMedia from "./PortfolioProjectMedia";

const sliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: false,
};

const PortfolioModal = ({
  project,
  name,
  client,
  languages,
  softwares,
  preview,
  medias,
}) => {
  return (
    <div className="slideshow">
      <figure>
        <figcaption>
          <h3>{name}</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Project </span>:{" "}
              <span className="ft-wt-600 uppercase">{project}</span>
            </div>

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Client </span>:{" "}
              <span className="ft-wt-600 uppercase">{client}</span>
            </div>

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">Software </span>:{" "}
              <span className="ft-wt-600 uppercase">
                {softwares.join(", ")}
              </span>
            </div>

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Preview </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a href={preview} target="_blank" rel="noreferrer">
                  {preview}
                </a>
              </span>
            </div>
          </div>
        </figcaption>

        {medias.length > 0 ? (
          <Slider {...sliderSettings}>
            {medias.map((media) =>
              <PortfolioProjectMedia
                type={media.type}
                url={media.url}
              />)}
          </Slider>
        ) : (
          <PortfolioProjectMedia
            type={medias[0]?.type ?? "image"}
            url={medias[0]?.url ?? "img/projects/project-1.jpg"}
          />
        )}
      </figure>
    </div>
  );
};

export default PortfolioModal;
