import React, { useState } from "react";
import Modal from "react-modal";
import BlogModal from "./BlogModal";

Modal.setAppElement("#root");

const Blog = ({ blogs }) => {
  const [activeBlogID, setActiveBlogID] = useState("");

  const isActive = (id) => activeBlogID === id;
  const toggleActive = (id) =>
    setActiveBlogID((activeID) => (activeID !== id ? id : "id"));

  console.log(blogs);

  return (
    <>
      {blogs.map((blog) => (
        <div key={blog.id} className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
          <article
            className="post-container"
            onClick={() => toggleActive(blog.id)}
          >
            <div className="post-thumb">
              <div className="d-block position-relative overflow-hidden">
                <img src={blog.image} style={{height: "230px", objectFit: "cover"}} className="img-cover" alt="Blog Post" />
              </div>
            </div>
            {/* End .thumb */}
            <div className="post-content">
              <div className="entry-header">
                <h3>{blog.title}</h3>
              </div>
              <div className="entry-content open-sans-font">
                <p>
                  {blog.content.length > 50
                    ? `${blog.content.substring(0, 47)}...`
                    : blog.content}
                </p>
              </div>
            </div>
            {/* End .post-content */}
          </article>

          {/* Start ModalTwoBlogContent */}
          <Modal
            isOpen={isActive(blog.id)}
            onRequestClose={() => toggleActive(blog.id)}
            contentLabel="My dialog"
            className="custom-modal dark"
            overlayClassName="custom-overlay dark"
            closeTimeoutMS={500}
          >
            <div>
              <button
                className="close-modal"
                onClick={() => toggleActive(blog.id)}
              >
                <img src="/img/cancel.svg" alt="close icon" />
              </button>
              {/* End close icon */}

              <div className="box_inner blog-post">
                <BlogModal
                  title={blog.title}
                  author={blog.author}
                  image={blog.image}
                  tags={blog.tags}
                  content={blog.content}
                  dateCreated={blog.dateCreated}
                />
              </div>
            </div>
          </Modal>
          {/* End  ModalTwoBlogContent */}
        </div>
      ))}
    </>
  );
};

export default Blog;
