import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import PortfolioProjects from "./PortfolioProjects";

const getCategories = (projects) => {
  const categories = new Set();
  projects.forEach((project) => {
    if (project.category) {
      categories.add(project.category);
    }
  });

  return Array.from(categories);
};

const Portfolio = ({ projects }) => {
  const [activeProjectID, setActiveProjectID] = useState("");
  const categories = getCategories(projects);

  const isActive = (id) => activeProjectID === id;
  const toggleActive = (id) =>
    setActiveProjectID((activeID) => (activeID !== id ? id : "id"));

  return (
    <Tabs>
      <TabList className="portfolio-tab-list">
        <Tab>ALL</Tab>
        {categories.map((category) => (
          <Tab key={category}>{category}</Tab>
        ))}
      </TabList>

      <div className="portfolio-tab-content">
        <TabPanel>
          <PortfolioProjects 
            isActive={isActive}
            toggleActive={toggleActive}
            projects={projects}               
          />
        </TabPanel>

        {categories.map((category) => (
          <TabPanel key={category}>
            <PortfolioProjects
              isActive={isActive}
              toggleActive={toggleActive}
              projects={projects.filter(
                (project) => project.category === category
              )}
            />
          </TabPanel>
        ))}
      </div>
    </Tabs>
  );
};

export default Portfolio;
