import React from "react";

const skillsContent = [
  { skillClass: "p80", skillPercent: "80", skillName: "Photoshop" },
  { skillClass: "p73", skillPercent: "73", skillName: "Premier Pro" },
  { skillClass: "p60", skillPercent: "60", skillName: "After Effects" },
  { skillClass: "p69", skillPercent: "69", skillName: "Figma" },
  { skillClass: "p90", skillPercent: "90", skillName: "Lightroom" },
  { skillClass: "p35", skillPercent: "35", skillName: "illustrator" },
  { skillClass: "p30", skillPercent: "30", skillName: "Cinema 4D" },
  { skillClass: "p15", skillPercent: "15", skillName: "Unreal Engine 5" },
];

const Skills = () => {
  return (
    <>
      {skillsContent.map((val, i) => (
        <div className="col-6 col-md-3 mb-3 mb-sm-5" key={i}>
          <div className={`c100 ${val.skillClass}`}>
            <span>{val.skillPercent}%</span>
            <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
            </div>
          </div>
          <h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
            {val.skillName}
          </h6>
        </div>
      ))}
    </>
  );
};

export default Skills;
