import React from "react";

const experienceContent = [
  {
    year: "   2021 - 2022",
    position: " Retoucher / Motion Designer",
    compnayName: "Kazan Express / Freelance",
    details: `Worked as a Reshutsher and graphic designer for a marketplace`,
  },
  {
    year: "2020 - 2021",
    position: " Video editor / Motion designer",
    compnayName: "Unicef Tajikistan",
    details: `Filmed and edited videos for UNICEF Tajikistan. There were also animated videos.`,
  },
  {
    year: "2018 - 2020",
    position: "Graphic Designer",
    compnayName: "Du#Du Mobile",
    details: `Worked as a graphic designer for over 2 years, making banners, price tags, advertising posters and video reviews`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
