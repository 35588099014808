import Modal from "react-modal";
import PortfolioModal from "./PortfolioModal";

const defaultThumbnailUrl = "img/projects/project-1.jpg"

const getThumbnailFromYoutubeEmbedUrl = (url) => {
  const videoID = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
  return `https://img.youtube.com/vi/${videoID}/0.jpg`;
};

const getProjectThumbnail = (project) => {
    const media = project.medias[0];
    const thumbnail = () =>  media.type === "youtube" ? getThumbnailFromYoutubeEmbedUrl(media.url) : media.url;

    return media ? thumbnail(): defaultThumbnailUrl;
};

const PortfolioProjects = ({ projects, toggleActive, isActive }) => {
  return (
    <ul className="row grid justify-content-center">
      {projects.map((project) => (
        <li
          key={project.id}
          className="direction-reveal"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="0"
        >
          <figure onClick={() => toggleActive(project.id)}>
            <img style={{objectFit: "cover", height: "230px" }} src={getProjectThumbnail(project)} alt="Portolio" />
            <div className=" hover-content-wrapper">
              <span className="content-title">{project.name}</span>
            </div>
          </figure>

          <Modal
            isOpen={isActive(project.id)}
            onRequestClose={() => toggleActive(project.id)}
            contentLabel="My dialog"
            className="custom-modal dark"
            overlayClassName="custom-overlay dark"
            closeTimeoutMS={500}
          >
            <div>
              <button
                className="close-modal"
                onClick={() => toggleActive(project.id)}
              >
                <img src="/img/cancel.svg" alt="close icon" />
              </button>

              <div className="box_inner portfolio">
                <PortfolioModal
                  name={project.name}
                  medias={project.medias}
                  project={project.project}
                  category={project.category}
                  client={project.client}
                  softwares={project.softwares}
                  preview={project.preview}
                />
              </div>
            </div>
          </Modal>
        </li>
      ))}
    </ul>
  );
};

export default PortfolioProjects;
