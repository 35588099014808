import React from "react";

const educationContent = [
  {
    year: "2022",
    degree: "Motion Designer",
    institute: "isaev Workshop",
    details: `  Passed to the third stage of the Motion 4.0 course. Work went in Cinema 4D and then in Unreal Engine.`,
  },
  {
    year: "2020",
    degree: "Logo animator",
    institute: "Motion Design School",
    details: `Completed a course on Logo Animation After Effects. Work can be seen in the portfolio`,
  },
  {
    year: "2018",
    degree: "Self-taught graphic design",
    institute: "YouTube )",
    details: `Studied the basics of Photoshop and editing on Premiere Pro`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
