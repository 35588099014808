import React from "react";

const BlogModal = ({ title, author, image, dateCreated, tags, content }) => {
  return (
    //  Article Starts
    <article>
      <div className="title-section text-left text-sm-center">
        <h1>
          Post <span>Details</span>
        </h1>
        <span className="title-bg">posts</span>
      </div>
      {/* Meta Starts */}

      <div className="meta open-sans-font">
        <span>
          <i className="fa fa-user"></i> {author}
        </span>
        <span className="date">
          <i className="fa fa-calendar"></i> {dateCreated}
        </span>
        <span>
          <i className="fa fa-tags"></i> {tags.join(", ")}
        </span>
      </div>
      {/* Meta Ends */}
      {/* Article Content Starts */}

      <h1>{title}</h1>
      <img src={image} className="img-fluid" alt="Blog" />
      <div className="blog-excerpt open-sans-font pb-5">
        <p>{content}</p>
      </div>
      {/* Article Content Ends */}
    </article>
    // Article Ends
  );
};

export default BlogModal;
