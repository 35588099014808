const PortfolioProjectMedia = ({ type, url }) => {
    if (type === "youtube") {
        return (
            <iframe
                className="youtube-video"
                title="youtube video"
                src={url}
                width={600}
                height={320}
                allowFullScreen
            ></iframe>
        )
    }

    if (type === "video") {
        return (
            <div className="videocontainer">
                <video
                    id="video"
                    className="responsive-video"
                    controls
                >
                    <source src={url} type="video/mp4" />
                </video>
            </div>
        )
    }

    return (
        <div className="slide_item">
            <img src={url} alt="slide 1" />
        </div>
    )
}

export default PortfolioProjectMedia;